import { useQuery } from "@apollo/client";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Alert, Avatar, CardActionArea, CardActions, Button, Modal, FormControl, InputLabel, OutlinedInput, Input, FormControlLabel, Select, MenuItem } from "@mui/material";
import { PROFILE_DATA, CURRENCIES } from "../../../graphql/queries";
import ProfImage from "../../images/profileimage.jpg";
import { Box } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UPDATE_OR_CREATE_USER } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from '@mui/material/Switch';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const CompanyProfile = () => {

  const [user, setUser] = useState();
  const [currency, setCurrency] = useState("");
  const [numberFormatStyle, setNumberFormatStyle] = useState("");
  const [checked, setChecked] = useState(false);
  const [currenciesData, setCurrenciesData] = useState();
  const { data, refetch } = useQuery(PROFILE_DATA);
  const { data: currencies } = useQuery(CURRENCIES);
  const { t } = useTranslation('global');
  const [modalOpen, setmodalOpen] = useState(false);
  const [errors, setErrors] = useState(false);
  const [editCompanyData, setEditCompanyData] = useState({
    name: "",
    contact: "",
    address: "",
    currency_id: 0,
    role_id: 2,
    tolerance :"",
    out_of_schedule : false
  });

  const handleOpen = () => { 
    setEditCompanyData(user);
    setCurrency(user?.currency?.id)
    setNumberFormatStyle(user?.number_format_style);
    setmodalOpen(true);
  };

  const numberFormats = [
    {
      format: 'american',
      label: 'American'
    },
    {
      format: 'european',
      label: 'European'
    }
  ];

  const handleClose = () => setmodalOpen(false);

  const onValueChange = (e) => {
    let newData = { ...editCompanyData };
    let name = e.target.name;
    let val = e.target.value;
    if (
      name === "name" ||
      name === "email" ||
      name === "contact" ||
      name === "address" || 
      name === "tolerance" 
    ) {
      console.log(newData, name, val);
      newData = { ...newData, [name]: val };
    }else if(name === "currency_id"){
      setCurrency(e.target.value);
      newData = { ...newData, [name]: val };
    }else if(name === "number_format_style"){
      setNumberFormatStyle(e.target.value);
      newData = { ...newData, [name]: val };
    }else if(name === "out_of_schedule"){
      setChecked(e.target.checked);
    }
    setEditCompanyData(newData);
  };


  const [updateUser, { loading: companyLoading }] = useMutation(UPDATE_OR_CREATE_USER, {
    onCompleted: ({ updateUser }) => {
      refetch();
    },
    onError:(()=>{
      console.log("error occurred");
    })
  });

  const updateData = async () => {
    try {
      const errors = [];
    
      if (!editCompanyData.name) {
        errors.push('Name is required');
      }
    
      if (!editCompanyData.address) {
        errors.push('Address is required');
      }
    
      if (
        editCompanyData.tolerance < 10
      ) {
        errors.push('Tolerance should be greater than or equal to 10');
      }
    
      if (errors.length === 0) {
        const response = await updateUser({
          variables: {
            id: editCompanyData.id,
            name: editCompanyData.name,
            contact: editCompanyData.contact,
            email: editCompanyData.email,
            address: editCompanyData.address,
            role_id: parseInt(editCompanyData.role.id),
            currency_id: editCompanyData.currency_id ?? null,
            tolerance: editCompanyData.tolerance ? parseInt(editCompanyData.tolerance): "",
            number_format_style: numberFormatStyle,
            out_of_schedule : checked 
          },
        });
    
        console.log(response);
        if (response && response.data) {
          setmodalOpen(false);
          setErrors(false);
        } else if (response && response.errors) {
          const graphQLErrors = response.errors.graphQLErrors;
          if (graphQLErrors) {
            const errorMessages = [];
            graphQLErrors.forEach((error) => {
              const validationErrors = error.extensions.validation;
              if (validationErrors) {
                Object.keys(validationErrors).forEach((fieldName) => {
                  const errorMessage = validationErrors[fieldName][0];
                  errorMessages.push(errorMessage);
                });
              }
            });
    
            setErrors(errorMessages);
          }
        }
      } else {
        setErrors(errors);
      }
    } catch (error) {
      console.error("Error updating employee:", error);
    }
    
  };

  useEffect(() => {
    try {
      setUser(data.me);
      setChecked(data.me.out_of_schedule===1?true:false);
      setNumberFormatStyle(data.me.number_format_style);
    } catch (error) {
      console.log(error.message);
    }
  }, [data]);

  useEffect(() => {
    try {
      setCurrenciesData(currencies?.currencies);
    } catch (error) {
      console.log(error.message);
    }
  }, [currencies]);

  return (
    <>
    <Box
      display="flex"
      justifyContent={"center"}
      gap={3}
      padding={4}
      width="100%"
    >
      <Card
        sx={{
          width: {
            xs: 500,
            sm: 600,
            md: 900,
          },
          paddingBottom:2,
        }}
      >
        <CardActionArea sx={{ display: "flex", justifyContent: "center" }}>
          <Avatar src={ProfImage} sx={{ width: 300, height: 300 }} />
        </CardActionArea>
        {user ? (
          <CardContent sx={{ paddingX: 5 }}>
            {/* <Typography sx={{ width: "100%" }} variant="h5" marginBottom={2}>
              {user ? user.role.name.toUpperCase() : ""}
            </Typography> */}
            <Box display="block">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                 
                }}
              >
                <Typography
                  width="60%"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                    fontSize: 18,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    { t('general.name') }:
                  </Typography>
                  {user ? user.name : ""}
                </Typography>
                <Typography
                  width="60%"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                    fontSize: 18,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    { t('general.address') }:
                  </Typography>
                  {user ? user.address : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  width="60%"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                    textAlign: "start",
                    fontSize: 18,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    { t('general.email') }:
                  </Typography>
                  {user ? user.email : ""}
                </Typography>
                <Typography
                  width="60%"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                    fontSize: 18,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    { t('general.contact') }:
                  </Typography>
                  {user ? user.contact : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  width="60%"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                    fontSize: 18,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    { t('general.currency') }:
                  </Typography>
                  {user ? user?.currency?.name : ""}
                </Typography>
                <Typography
                  width="60%"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                    fontSize: 18,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    { t('general.tolerance') }:
                  </Typography>
                  { user?.tolerance } {t('general.minutes')}              
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  width="60%"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: 1,
                    fontSize: 18,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    { t('general.number_format_style') }:
                  </Typography>
                  {numberFormatStyle ?? ""}    
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px  ",
                }}
              >
              <FormControlLabel
                disabled
                control={<Switch checked={checked} />}
                label="Out of schedule"
              />
              </Box> 
            </Box>
          </CardContent>
        ) : (
          <Typography
            sx={{
              marginTop: 3,
              textAlign: "center",
              fontSize: 24,
              fontWeight: "large",
              color: "#4793F7",
            }}
          >
            Loading...
          </Typography>
        )}
        <CardActions
          sx={{ display: "flex", justifyContent: "center", gap: 2, marginY: 5 }}
        >
          <Button size="large" sx={{ fontSize: 18 }} color="primary" onClick={handleOpen}>
            Update Profile
          </Button>
        </CardActions>
      </Card>
    </Box>

    {/*  Update profile modal */}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <Typography variant="h6" textAlign="center">
             { t('companies.edit_company_details') }
            </Typography>
            <FormControl fullWidth>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="name">
                { t('general.name') }
              </InputLabel>
              <OutlinedInput
                id="name"
                sx={{ m: 1 }}
                fullWidth
                name="name"
                value={editCompanyData?.name}
                onChange={(e) => onValueChange(e)}
                label="name"
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: "6px 0px 6px 8px" }}>
              <InputLabel htmlFor="currency">
                  { t('general.currency') }
                </InputLabel>
                <Select
                  name="currency_id"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currency}
                  label="Currency"
                  onChange={(e) => onValueChange(e)}
                  disabled={user?.currency_id ? true : false}
                >
                  {currenciesData && currenciesData.map(currency => (
                    <MenuItem key={currency.id} value={currency.id}>
                     {`${currency.symbol} - ${currency.name}`}
                    </MenuItem>
                  ))}
               
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="contact">
              { t('general.contact') }
              </InputLabel>
              <OutlinedInput
                id="contact"
                sx={{ m: 1 }}
                fullWidth
                name="contact"
                value={editCompanyData?.contact}
                onChange={(e) => onValueChange(e)}
                label="contact"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="address">
              { t('general.address') }
              </InputLabel>
              <OutlinedInput
                id="address"
                sx={{ m: 1 }}
                fullWidth
                name="address"
                value={editCompanyData?.address}
                onChange={(e) => onValueChange(e)}
                label="address"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ m: "5px 10px" }} htmlFor="tolerance">
              { t('general.tolerance') }
              </InputLabel>
              <OutlinedInput
                id="tolerance"
                sx={{ m: 1 }}
                fullWidth
                name="tolerance"
                value={editCompanyData?.tolerance}
                onChange={(e) => onValueChange(e)}
                label="tolerance"
              />
            </FormControl>
            
            <FormControl fullWidth sx={{ m: "6px 0px 6px 8px" }}>
              <InputLabel htmlFor="number_format_style">
                  { t('general.number_format_style') }
                </InputLabel>
                <Select
                  name="number_format_style"
                  labelId="number-format-select-label"
                  id="number-format-select"
                  value={numberFormatStyle}
                  label="number_format_style"
                  onChange={(e) => onValueChange(e)}
                >
                {numberFormats.map((format, index) => (
                  <MenuItem key={index} value={format.format}>
                    {`${format.label}`}
                  </MenuItem>
                ))}
               
              </Select>
            </FormControl>


            <FormControlLabel
              control={
                <Switch 
                  checked={checked}  
                  onChange={(e) => onValueChange(e)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  name="out_of_schedule" />
                }
                label="Out of schedule"
            />

            
            <Input
              type="hidden"
              name="role_id"
              value={editCompanyData.role_id}
            />

            {errors?.length > 0 && (
              <div>
                {errors.map((errorMessage, index) => (
                  <Alert key={index} severity="error">
                    {errorMessage}
                  </Alert>
                ))}
              </div>
            )}

          </Box>
          <Box sx={{ mt: 3, mb: 2, textAlign: "center" }}>
            <Button
              onClick={() => {
                handleClose();
                setErrors(false);
              }}
              variant="contained"
              sx={{
                minWidth: "150px",
                p: 1,
                m: 1,
                bgcolor: "#ff9800",
                ":hover": { bgcolor: "#ff9800" },
              }}
            >
              { t('general.cancel') }
            </Button>
            <Button
              sx={{
                minWidth: "150px",
                p: 1,
                m: 1,
                bgcolor: "#F16101",
                ":hover": { bgcolor: "#F16101" },
              }}
              variant="contained"
              onClick={() => updateData() }
              disabled={companyLoading}
            >
              {companyLoading ? (
                <CircularProgress size={24} color="primary" />
              ) : ( 
                  t('general.update')
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CompanyProfile;
