
// const GRAPHQL_URL = "http://localhost/timeinout-backend/public/graphql"
// const APP_URL = 'http://localhost/timeinout-backend/';
// const FRONT_END_URL = "http://localhost:3000/";

// const GRAPHQL_URL = "https://cico.tangiblethemes.com/graphql"
// const APP_URL = 'https://cico.tangiblethemes.com/';
// const FRONT_END_URL = "https://appcico.tangiblethemes.com/";

const GRAPHQL_URL = "https://deapi.timetracksolutions.com/graphql"
const APP_URL = 'https://deapi.timetracksolutions.com';
const FRONT_END_URL = "https://app.smart-cico.de/";

const MAP_API_KEY="AIzaSyDj8raFmgne-CAknrw-Zyzl7iE6sXaOE8A";

export {APP_URL, GRAPHQL_URL, FRONT_END_URL, MAP_API_KEY};