import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      token
      user {
        id
        language
        role {
          id
        }
      }
    }
  }
`;
export const SIGNUP_MUTATION = gql`
  mutation SignupMutation(
    $email: String!
    $password: String!
    $password_confirmation: String!
    $role_id: Int!
    $name: String!
  ) {
    register(
      input: {
        email: $email
        password: $password
        password_confirmation: $password_confirmation
        role_id: $role_id
        name: $name
      }
    ) {
      token
      status
      user {
        id
        role {
          id
        }
      }
    }
  }
`;

export const FORGET_PASSWORD = gql`
  mutation forgetPassword($email: String!, $reset_password_url: ResetPasswordUrlInput!) {
    forgotPassword(input: {
      email: $email,
      reset_password_url: $reset_password_url
    }) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $token: String!, $password: String!, $password_confirmation: String!) {
    resetPassword(input: {
      email: $email,
      token: $token
      password: $password
      password_confirmation: $password_confirmation
    }) {
      status
      message
    }
  }
`;


////////////////////////////////////////
//.........Change language..........
export const UPDATE_USER_LANGUAGE  = gql`
  mutation updateUserLanguage(
    $id: UUID!
    $language: String!
  ) {
    updateUserLanguage(
      id: $id
      language: $language
    ) {
      id
      language
      role {
        id
      }
    }
  }
`;
//.........Companies Mutations..........
export const UPDATE_OR_CREATE_USER = gql`
  mutation updateOrCreateUser(
    $name: String!
    $email: String!
    $contact: String
    $address: String!
    $role_id: Int!
    $parent_id: UUID
    $currency_id: ID
    $tolerance: Int,
    $out_of_schedule : Boolean,
    $number_format_style: String
    $id: UUID
  ) {
    updateOrCreateUser(
      input: {
        id: $id
        name: $name
        email: $email
        contact: $contact
        address: $address
        role_id: $role_id
        parent_id: $parent_id
        currency_id: $currency_id
        tolerance: $tolerance
        out_of_schedule: $out_of_schedule
        number_format_style: $number_format_style
      }
    ) {
      id
      email
      name
      contact
      address
      role {
        id
      }
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation deleteUser($id: UUID!) {
    deleteUser(id: $id) {
      success
      message
    }
  }
`;
///////////////////////////////
// .....Costumers Mutations.........
export const UPDATE_OR_CREATE_CUSTOMER = gql`
  mutation updateOrCreateCustomer(
    $id: UUID
    $parent_id: UUID
    $name: String!
    $email: String
    $paid_per_hr: Float
    $phone: String
    $address: String
  ) {
    updateOrCreateCustomer(
      input: {
        id: $id
        user_id: $parent_id
        name: $name
        email: $email
        paid_per_hr: $paid_per_hr
        phone: $phone
        address: $address
      }
    ) {
      id
      email
      name
      phone
      address
      paid_per_hr
    }
  }
`;

///////////////////////////////
export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($id: UUID!) {
    deleteCustomer(id: $id) {
      success
      message
    }
  }
`;
// .....Employee Mutations.........
export const UPDATE_OR_CREATE_EMPLOYEE = gql`
  mutation updateOrCreateUser(
    $name: String!
    $email: String
    $wage: Float
    $contact: String
    $address: String
    $role_id: Int!
    $parent_id: UUID
    $id: UUID
  ) {
    updateOrCreateEmployee: updateOrCreateUser(
      input: {
        id: $id
        name: $name
        email: $email
        wage: $wage
        contact: $contact
        address: $address
        role_id: $role_id
        parent_id: $parent_id
      }
    ) {
      id
      email
      name
      contact
      wage
      address
      status
      role {
        id
      }
    }
  }
`;

// Send CheckOut Data...............
export const SEND_CHECKOUT_DATA = gql`
  mutation CheckOut(
    $id: UUID
    $start_time: DateTime!
    $end_time: DateTime!
    $location: JSON
    $employee_id: UUID!
    $customer_id: UUID!
    $company_id: UUID!
  ) {
    CheckOut(
      input: {
        id: $id
        start_time: $start_time
        end_time: $end_time
        location: $location
        employee_id: $employee_id
        customer_id: $customer_id
        company_id: $company_id
      }
    ) {
      id
      start_time
      end_time
      variance
      total_time
      schedule {
        start_time
        end_time
      }
    }
  }
`;

///////////////////////////////////////////
/////// Approved Checkout Mutation ////////

export const APPROVED_CHECKOUT = gql`
mutation ApproveCheckout($schedule_id:UUID! $checkout_id:UUID!){
  ApproveCheckout(input:{
    schedule_id: $schedule_id
    checkout_id: $checkout_id
  }){
    id
    start_time
    end_time
    variance
    total_time
  }
}
`;

export const DELETE_CHECKOUT = gql`
  mutation deleteCheckout($id: UUID!, $company_id:UUID!) {
    deleteCheckout(id: $id, company_id: $company_id) {
      success
      message
    }
  }
`;

export const PAGE_NOT_FOUND = gql`
mutation pageNotFound($url:String! ){
  pageNotFound(
    url: $url
  ){
    id
    log_name
    description
  }
}
`;

export const DELETE_EMPLOYEE = gql`
  mutation deleteEmployee($id: UUID!) {
    deleteUser(id: $id) {
      success
      message
    }
  }
`;
export const CHANGE_EMPLOYEE_STATUS = gql`
  mutation changeEmployeeStatus($id: UUID!, $newStatus: String!) {
    changeEmployeeStatus(input: {
      id: $id, 
      newStatus: $newStatus
    }) {
      id
      name
      email
      wage
      contact
      address
      status
      role{
        name
        created_at
      }
    }
  }
`;

////////////////////////////////////////////
//////Schedule Mutation////////////////////
export const CREATE_SCHEDULES = gql`
  mutation CreateOrUpdateSchedule(
    $customer_id: UUID!
    $schedules: [SchedulesInput!]!
  ) {
    CreateOrUpdateSchedule(
      input: { customer_id: $customer_id, schedules: $schedules }
    ) {
      message
    }
  }
`;

export const UPDATE_SCHEDULE = gql`
  mutation CreateOrUpdateSchedule(
    $id: UUID
    $customer_id: UUID!
    $schedules: [SchedulesInput!]!
  ) {
    CreateOrUpdateSchedule(
      input: { id: $id, customer_id: $customer_id, schedules: $schedules }
    ) {
      message
    }
  }
`;

//////Schedule Mutation////////////////////
// export const CREATE_SCHEDULE = gql`
//   mutation CreateOrUpdateSchedule(
//     $customer_id: UUID!
//     $employee_id: UUID!
//     $start_time: DateTime!
//     $end_time: DateTime!
//     $wage: Float!
//   ) {
//     CreateOrUpdateSchedule(
//       input: {
//         customer_id: $customer_id
//         employee_id: $employee_id
//         start_time: $start_time
//         end_time: $end_time
//       }
//     ) {
//       customer {
//         name
//       }
//       employee {
//         name
//       }
//     }
//   }
// `;

export const DELETE_SCHEDULE = gql`
  mutation deleteSchedule($id: UUID!) {
    deleteSchedule(id: $id) {
      id
    }
  }
`;

export const SEND_QR_IMAGE = gql`
mutation SendQRImage($image: Upload!, $companyName: String!) {
  sendQRImage(image: $image, companyName: $companyName){
    pdf
  }
}
`;


