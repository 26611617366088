import { useQuery, useMutation } from "@apollo/client";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import {
  DataGrid,
  GridToolbar
} from "@mui/x-data-grid";
import {
  Box, 
  Divider,
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  styled,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PopupState, {
  bindPopover,
  bindTrigger,
  bindToggle,
} from "material-ui-popup-state";
import swal from "sweetalert";
import DetailsIcon from '@mui/icons-material/Details';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useState, useEffect } from "react";
import { WORK_HISTORIES } from "../../../graphql/queries";
import Filter from "../../Filter";
import dayjs from 'dayjs';
import ReusableDatePicker from "../../ReusableDatePicker";
import { DELETE_CHECKOUT } from "../../../graphql/mutations";
import { useTranslation } from "react-i18next";
import { AUTH_ID } from "../../Authentication/constant";

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
  },
}));

const EmployeeReport = () => {
  const [report, setReport] = useState([]);
  const [pageSize, setPageSize] = useState(8);
  const [selectedOption, setSelectedOption] = useState(null);
  const [resetKey, setResetKey] = useState(0);
  const [fromPickerValue, setFromPickerValue] = useState(null);
  const [toPickerValue, setToPickerValue] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [dateRangeLoading, setDateRangeLoading] = useState(false);
  const {t} = useTranslation('global');

  const options = [
    { label: t('apply_filter.today'), value: "today" },
    { label: t('apply_filter.last_week'), value: "last_week" },
    { label: t('apply_filter.last_month'), value: "last_month" },
    { label: t('apply_filter.last_6_months'), value: "last_6_months" },
    { label: t('apply_filter.last_year'), value: "last_year" },
  ];


  const handleReset = () => {
    setDateRangeLoading(true);
    setResetKey(prevKey => prevKey + 1);

    // Reset the date picker values to null
    setFromPickerValue(null);
    setToPickerValue(null);
  
    // Reset both dateFrom and dateTo to null
    setDateFrom(null);
    setDateTo(null);
  
    refetch({
      filter: selectedOption ? selectedOption.value : null,
      date_from: null,
      date_to: null,
    }).then(() => {
      setDateRangeLoading(false);
    });
  };

  const handleSelectionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // delete checkout mutation.
  const [deleteCheckout] = useMutation(DELETE_CHECKOUT);
  const handleDeleteCheckout = (id) => {
    swal({
      title: t('pop_ups.are_you_sure'),
      text: t('pop_ups.checkout_delete_confirm'),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCheckout({
          variables: {
            id: id,
            company_id: AUTH_ID
          }
        })
          .then((response) => {
         
            if(response.data.deleteCheckout.success){

              const indexToDelete = report.findIndex((customer) => customer.id === id);
              if (indexToDelete !== -1) {
                // Create a new array without the deleted row
                const updatedCheckouts = [...report];
                updatedCheckouts.splice(indexToDelete, 1);
                setReport(updatedCheckouts);
              }

              swal(t('pop_ups.checkout_deleted'), {
                icon: "success",
              });
            }else{
              swal(response.data.deleteCheckout.message, {
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            swal(t('pop_ups.error_occurred'), {
              icon: "error",
            });
          });
      } else {
        swal(t('pop_ups.checkout_is_safe'));
      }
    });
  };

  //reports data
  const { data, loading, refetch } = useQuery(WORK_HISTORIES, {
    variables: {
      filter: selectedOption ? selectedOption.value : null,
      date_from : null,
      date_to: null
    },
    fetchPolicy: "no-cache"
  });
  const columns = [
    // { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "customer",
      headerName: t('general.customer'),
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => `${params.row.customer.name}`,
    },
    {
      field: "employee",
      headerName: t('general.employee'),
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => `${params.row.employee.name}`,
    },
    {
      field: "start_time",
      headerName: t('general.start_time'),
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        const date = new Date(params.row.start_time);
        return date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });
      },
    },
    {
      field: "end_time",
      headerName: t('general.end_time'),
      flex: 1,
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        const date = new Date(params.row.end_time);
        return date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });
      },
    },
    {
      field: "date",
      headerName: t('general.date'),
      flex: 1,
      minWidth: 60,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        const dateTime = new Date(params.row.end_time);
        const options = {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        };
        const formattedDate = dateTime.toLocaleDateString(undefined, options);
    
        return formattedDate;
      },
    },    
    {
      field: "schedule",
      headerName: t('reports.schedule_time'),
      flex: 1,
      minWidth: 100,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        if (params.row.schedule) {
          const startTime = new Date(params.row.schedule.start_time);
          const endTime = new Date(params.row.schedule.end_time);

          const options = { hour: "numeric", minute: "2-digit", hour12: true };
          const formattedStartTime = startTime.toLocaleTimeString([], options);
          const formattedEndTime = endTime.toLocaleTimeString([], options);

          return `${formattedStartTime} - ${formattedEndTime}`;
        } else {
          return "";
        }
      },
    },
    {
      field: "variance",
      headerName: t('reports.variance'),
      flex: 1,
      minWidth: 330,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        if (params.row.variance) {
          const variance = JSON.parse(params.row.variance);
          let start_time, end_time;
          if (variance.start_time > 0) {
            const hours = Math.floor(variance.start_time / 60);
            const minutes = variance.start_time % 60;

            start_time =
              "Started " +
              (hours ? hours + " hrs " : " ") +
              (minutes ? minutes + " mnt " : " ") +
              "Early";
          } else if (variance.start_time < 0) {
            const absoluteTime = Math.abs(variance.start_time);
            const hours = Math.floor(absoluteTime / 60);
            const minutes = absoluteTime % 60;

            start_time =
              "Started " +
              (hours ? hours + " hrs " : " ") +
              (minutes ? minutes + " mnt " : " ") +
              "Late";
          } else {
            start_time = "Started on time";
          }

          if (variance.end_time > 0) {
            const hours = Math.floor(variance.end_time / 60);
            const minutes = variance.end_time % 60;

            end_time =
              "Ended " +
              (hours ? hours + " hrs " : " ") +
              (minutes ? minutes + " mnt " : " ") +
              "Early";
          } else if (variance.end_time < 0) {
            const absoluteTime = Math.abs(variance.end_time);
            const hours = Math.floor(absoluteTime / 60);
            const minutes = absoluteTime % 60;

            end_time =
              "Ended " +
              (hours ? hours + " hrs " : " ") +
              (minutes ? minutes + " mnt " : " ") +
              "Late";

            // end_time = "Ended " + Math.abs(variance.end_time) + "mints Late";
          } else {
            end_time = "Ended on time";
          }

          return `${start_time}, ${end_time}`;
        } else {
          return "";
        }
      },
    },
    {
      field: "total_time",
      headerName: t('general.total_hrs_worked'),
      flex: 1,
      minWidth: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        if (params.row.total_minutes) {
          return params.row.total_minutes;
        } else {
          return "";
        }
      },
    },
    {
      field: "actions",
      headerName: t('general.actions'),
      minWidth: 100,
      flex: 1,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <StyledTableCell>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                {/* Action in Table Cell */}
                <MoreVertIcon
                  sx={{ color: "gray", fontSize: "28px" }}
                  {...bindTrigger(popupState)}
                />
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box sx={{ display: "block" }}>
                    <Link
                      to={`/dashboard/companyDashboard/checkoutdetail/${params.id}`}
                      style={{
                        textDecoration: "none",
                        color: "gray",
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <DetailsIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={ t('headings.checkout_details') } />
                      </ListItemButton>
                    </Link>

                    <Divider width="100%" flexItem />
                    <Link
                      onClick={ () => handleDeleteCheckout(params.id) }
                      style={{
                        textDecoration: "none",
                        color: "gray",
                      }}
                    >
                      <ListItemButton {...bindToggle(popupState)}>
                        <ListItemIcon>
                          <DeleteIcon color="error" />
                        </ListItemIcon>
                        <ListItemText primary={ t('checkouts.delete_checkout') } />
                      </ListItemButton>
                    </Link>

                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </StyledTableCell>
      ),
    },
    // {
    //   field: "location",
    //   headerName: "Location",
    //   flex: 1,
    //   minWidth: 80,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => (
    //     <a
    //       href={
    //         "https://www.google.com/maps/@" +
    //         JSON.parse(params.row.location).latitude +
    //         ", " +
    //         JSON.parse(params.row.location).longitude
    //       }
    //       target="_blank"
    //       rel="noreferrer"
    //     >
    //       <LocationOnIcon />
    //     </a>
    //   ),
    // },
  ];

  useEffect(() => {
    try {
      setReport(data.workHistories);
    } catch (error) {
      console.log(error.message);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [selectedOption, refetch]);

  return (
    <>
      <Grid container alignItems="center" sx={{ marginBottom: "20px" }}>
        <Grid item xs={4}>
          <h2>{ t('headings.checkin_checkout_report') }</h2>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end">
          {/* date from */}
          <ReusableDatePicker
           key={`fromDatePicker-${resetKey}`} // Use a unique key for each DatePicker
           label= { t('general.from') }
            initialValue={fromPickerValue}
            onDateChange={(newValue) => { 
              setDateRangeLoading(true);
              const formattedDate = newValue ? dayjs(new Date(newValue.toString())).format("YYYY-MM-DD") : null;
              setDateFrom(formattedDate);
              refetch({
                date_from: formattedDate,
                date_to: dateTo,
              }).then(() => {
                setDateRangeLoading(false);
              });
            }}
          />
          {/* date to */}
          <ReusableDatePicker
           key={`toDatePicker-${resetKey}`} 
           label={ t('general.to') }
            initialValue={toPickerValue}
            onDateChange={(newValue) => { 
              setDateRangeLoading(true);
              const formattedDate = newValue ?dayjs(new Date(newValue.toString())).format("YYYY-MM-DD"): null;
              setDateTo(formattedDate);
              refetch({
                date_from: dateFrom,
                date_to: formattedDate,
              }).then(() => {
                setDateRangeLoading(false);
              });
            }}
          />
          <Button variant="contained" color="primary" onClick={handleReset} sx={{marginRight: "40px"}}>
          { t('general.reset') }
          </Button>
          <Filter options={options} onChange={handleSelectionChange} />
        </Grid>
      </Grid>
      <Box
        sx={{
          height: 700,
          width: "100%",
          "& .super-app-theme--header": {
            fontWeight: "bolder",
            fontSize: 18,
          },
        }}
      >
        <DataGrid
          // autoHeight
          loading={dateRangeLoading || loading}
          columns={columns}
          rows={report}
          rowHeight={60}
          pageSize={pageSize}
          rowsPerPageOptions={[8, 10, 20]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          slots={{ toolbar: GridToolbar }}
          localeText={{
            toolbarExport: t('data_grid.export'),
            toolbarExportCSV : t('data_grid.toolbar_export_csv'),
            toolbarExportPrint : t('data_grid.toolbar_export_print'),
            toolbarFilters : t('data_grid.toolbar_filters'),
            toolbarColumns : t('data_grid.toolbar_columns'),
            columnMenuSortAsc : t('data_grid.column_menu_sort_asc'),
            columnMenuSortDesc: t('data_grid.column_menu_sort_desc'),
            columnMenuHideColumn : t('data_grid.column_menu_hide_column'),
            columnMenuManageColumns : t('data_grid.column_menu_manage_columns'),
            toolbarDensity: t('data_grid.toolbar_density_label'),
            toolbarDensityLabel: t('data_grid.toolbar_density_label'),
            toolbarDensityCompact: t('data_grid.toolbar_density_compact'),
            toolbarDensityStandard: t('data_grid.toolbar_density_standard'),
            toolbarDensityComfortable: t('data_grid.toolbar_density_comfortable'),
            toolbarQuickFilterPlaceholder : t('data_grid.toolbar_quick_filter_label'),
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
            pagination: {
              labelRowsPerPage: t('data_grid.rows_per_page_options'),
              labelDisplayedRows : ({ from, to, count }) => {
                    const countText = count !== -1 ? count : `${t('general.more_than')} ${to}`;
                    return `${from}–${to} ${t('general.of')} ${countText}`;
                } 
            }
          }}
        />
      </Box>
    </>
  );
};

export default EmployeeReport;
